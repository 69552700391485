import * as React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import TipCard from '../components/tip-card';
import { tipList } from './topic.module.scss';

const TopicsPage = ({ data }) => {
    const displayName = data.topicsJson.displayName;

    return (
        <>
            <SEO
                title={`${displayName} Tips | Web Performance Tips`}
                description={`View Web Performance Tips for Topic ${displayName}.`}
            />
            <Layout pageTitle={data.topicsJson.displayName}>
                    <ul className={tipList}>
                        {
                            data.allMdx.nodes.map(node => {
                                return (
                                    <li key={node.id}>
                                        <TipCard node={node} />
                                    </li>
                                );
                            })
                        }
                    </ul>
            </Layout>
        </>
    );
}

export const query = graphql`
  query ($id: String, $topicIdRegex: String) {
      topicsJson(id: { eq: $id }) {
          displayName
      }
      allMdx(filter: { frontmatter: { topics: { regex: $topicIdRegex } } }, sort: { fields: frontmatter___date, order: DESC }) {
          nodes {
              frontmatter {
                  date(formatString: "MMMM D, YYYY")
                  title
                  topics
              }
              id
              excerpt
              slug
          }
      }
  }
`;

export default TopicsPage;
